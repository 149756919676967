import React from "react"
import styled from "styled-components"
import { color } from "../components/colors"

const Wrapper = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80px;
`

const Copyright = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
`

const Author = styled.a`
  color: ${color.secondary};
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: ${color.secondary};
  }
`

const Footer = () => {
  return (
    <Wrapper>
      <Copyright align={"center"} p={"0 0 15px"}>
        © {new Date().getFullYear()}, ALL RIGHTS RESERVED. DESIGNED & CODED BY
        {` `}
        <Author
          href="https://www.facebook.com/webscris"
          target="_blank"
          rel="noreferrer"
        >
          CRIS
        </Author>
      </Copyright>
    </Wrapper>
  )
}

export default Footer
