import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import { color } from "./colors"
import Container from "../atomic/partials/container"
import Phone from "../images/phone.inline.svg"

const CustomHeader = styled.header`
  display: flex;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  padding: 15px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  transition: padding 0.3s ease;

  @media (min-width: 992px) {
    align-items: center;
  }

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &.alt {
    background-color: #040404;
    //background-image: linear-gradient(90deg, ${color.black} 0%, ${color.secondary} 100%);
    //border-bottom: 1px solid ${color.black};
    padding: 5px 0;

    .logo {
      .gatsby-image-wrapper {
        border-color: #040404;
      }
    }

    nav {
      top: 50px;
    }

    ul {
      display: flex;
    }
  }

  svg {
    height: 14px;
    margin-right: 8px;

    path {
      fill: ${color.white};
    }
  }
`

const LogoWrapper = styled(Link)`
  width: 100%;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
  
  @media (min-width: 576px) {
    height: 60px;
    width: 60px;
  }

  @media (min-width: 1200px) {
    height: 80px;
    width: 80px;
  }

  span {
    font-size: 32px;
    letter-spacing: 8px;
  }

  .gatsby-image-wrapper {
    margin: 0 !important;
    height: 80px;
    width: 80px;
    border: 1px solid #373131;

    img {
      position: static !important;
    }
  }
`

const Navigation = styled.nav`
  height: 224px;
  position: absolute;
  left: 0;
  right: 0;
  top: 75px;
  background-color: ${color.black};
  //background-image: linear-gradient(90deg, ${color.black} 0%, ${color.secondary} 100%);
  transition: height 0.3s ease;
  overflow: hidden;

  @media (min-width: 769px) {
    position: static;
    background-color: transparent;
    background-image: none;
    height: auto;
    overflow: visible;
  }

  &.hidden {
    height: 0px;

    @media (min-width: 769px) {
      height: auto;
    }
  }
`;

const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 568px;
  list-style: none;
  padding: 32px 15px;
  margin: 0 auto;

  @media (min-width: 769px) {
    flex-direction: row;
    padding: 0;
  }

  li {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 0;

    @media (min-width: 769px) {
      width: auto;
    }

    a {
      color: ${color.white};
      font-size: 14px;
      font-height: 1;
      font-weight: 700;
      text-transform: uppercase;
      text-decoration: none;
      transition: color 0.3s ease;
      position: relative;

      &::before {
        content: "";
        width: 0%;
        border-bottom: 1px solid #fff;
        transition: width 0.3s ease;
        position: absolute;
        bottom: -8px;
      }

      &:hover {
        &::before {
          width: 100%;
        }
      }
    }
  }

  li:not(:last-child) {
    margin-bottom: 16px;

    @media (min-width: 769px) {
      margin-right: 16px;
      margin-bottom: 0;
    }
  }
  
  li:last-child {
    margin-top: 16px;

    @media (min-width: 769px) {
      margin-top: 0;
      margin-left: 24px;
    }
  }
`

const Hamburger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  width: 45px;
  padding: 5px;
  position: relative;
  background: transparent;
  border: 0;
  cursor: pointer;
  z-index: 1;

  @media (min-width: 769px) {
    display: none;
  }

  &:focus {
    outline-color: #fff;
  }

  span {
    display: block;
    height: 6px;
    width: 35px;
    background-color: #fff;
    border-radius: 2px;
    position: relative;
    transition: background-color 0.3s ease;
  }

  &.active {
    justify-content: center;
    padding: 2px;

    span {
      position: absolute;
      transition: transform 0.3s ease;

      &:nth-child(1) {
        top: calc(50% - 3px);
        transform: rotate(45deg);
        transform-origin: center;
      }

      &:nth-child(2) {
        display: none;
      }

      &:nth-child(3) {
        bottom: calc(50% - 3px);
        transform: rotate(-45deg);
        transform-origin: center;
      }
    }
  }
`

const Header = ({ logo }) => {
  const [hamburgerIsActive, setHamburgerIsActive] = useState(false)

  useEffect(() => {
    const hero = document.querySelector(".top-overlay")
    const header = document.querySelector(".header")

    if (hero) {
      const heroSize = hero.offsetHeight / 10

      if (document.documentElement.scrollTop > heroSize) {
        header.classList.add("alt")
      } else {
        header.classList.remove("alt")
      }

      window.onscroll = () => {
        if (document.documentElement.scrollTop > heroSize) {
          header.classList.add("alt")
        } else {
          header.classList.remove("alt")
        }
      }
    } else {
      header.classList.add("alt")
    }
  })

  const handleClick = e => {
    e.target.closest("button").classList.toggle("active")
    setHamburgerIsActive(!hamburgerIsActive)
  }

  return (
    <CustomHeader className="header">
      <Container position="static">
        <LogoWrapper to="/" className="logo">
          {logo && 
            <Img fixed={logo.ustawieniaLogoStrony ? logo.ustawieniaLogoStrony.localFile.childImageSharp.fixed : "/"} />
          }
        </LogoWrapper>
        <Hamburger 
        onClick={e => handleClick(e)}
        >
          <span />
          <span />
          <span />
        </Hamburger>
        <Navigation className={hamburgerIsActive ? "active" : "hidden"}>
          <NavList>
            <li>
              <Link to="/#o-mnie">O mnie</Link>
            </li>
            <li>
              <Link to="/#oferta">Oferta</Link>
            </li>
            <li>
              <Link to="/#realizacje">Realizacje</Link>
            </li>
            <li>
              <Link to="/#kontakt">Kontakt</Link>
            </li>
            <li>
              <Phone />
              <a href="tel:48789422711">+48 789 422 711</a>
            </li>
          </NavList>
        </Navigation>
      </Container>
    </CustomHeader>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
