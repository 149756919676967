export const color = {
  primary: "#ffb426",
  //secondary: "#ED6A5A",
  //secondary: "#ff4141",
  //secondary: "#ffb426",
  //secondary: "#1288f8",
  secondary: "#f7c628",
  //secondary: "#4f7b99",
  //third: "#f0764e",
  //third: "#f25654",
  third: "#e18551",
  //black: "#121212",
  black: "#0e0e0e",
  blueLight: "#D2E1E8",
  grey: "#464646",
  red: "#d7244c",

  grey1: "#4E4E4E",
  grey2: "#757575",
  grey3: "#DDDDDD",
  grey4: "#F4F4F4",
  grey5: "#FCFCFC",
  white: "#ffffff",
  babyblue1: "#A6D5E9",
  blue1: "#5089FD",
  green1: "#97E0C7",
  orange1: "#FD8550",
  purple1: "#C8A3EC",
  red1: "#F1A0A0",
  yellow1: "#F3D699",
  babyblue2: "#E0F4FD",
  blue2: "#D2E0FE",
  green2: "#D5F3E8",
  orange2: "#FCE7D0",
  purple2: "#EDE0FA",
  red2: "#F5CDCD",
  yellow2: "#FAEFC8",
  babyblue3: "#EEF9FE",
  blue3: "#F2F6FF",
  green3: "#F2F8F3",
  orange3: "#FEF6ED",
  purple3: "#FAF2F8",
  red3: "#FCEFEF",
  yellow3: "#FEF7E0",
  yellow4: "#FEFAF4",
}

export const gradient = {
  babyblue2: "linear-gradient(0deg, rgba(255,255,255,0.00) 4%, #E0F4FD 99%)",
  blue2: "linear-gradient(0deg, rgba(255,255,255,0.00) 4%, #D2E0FE 99%)",
  green2: "linear-gradient(0deg, rgba(255,255,255,0.00) 4%, #D5F3E8 99%)",
  orange2: "linear-gradient(0deg, rgba(255,255,255,0.00) 4%, #FCE7D0 99%)",
  orange2reverse:
    "linear-gradient(0deg, #FCE7D0 4%, rgba(255,255,255,0.00) 99%)",
  purple2: "linear-gradient(0deg, rgba(255,255,255,0.00) 4%, #EDE0FA 99%)",
  red2: "linear-gradient(0deg, rgba(255,255,255,0.00) 4%, #F5CDCD 99%)",
  yellow2: "linear-gradient(0deg, rgba(255,255,255,0.00) 4%, #FAEFC8 99%)",
  babyblue3: "linear-gradient(0deg, rgba(255,255,255,0.00) 4%, #EEF9FE 99%)",
  blue3: "linear-gradient(0deg, rgba(255,255,255,0.00) 4%, #F2F6FF 99%)",
  blue3reverse: "linear-gradient(0deg, #F2F6FF 4%, rgba(255,255,255,0) 99%)",
  green3: "linear-gradient(0deg, rgba(255,255,255,0.00) 4%, #F2F8F3 99%)",
  orange3: "linear-gradient(0deg, rgba(255,255,255,0.00) 4%, #FEF6ED 99%)",
  orange3reverse:
    "linear-gradient(0deg, #FEF6ED 4%, rgba(255,255,255,0.00) 99%)",
  purple3: "linear-gradient(0deg, rgba(255,255,255,0.00) 4%, #FAF2F8 99%)",
  red3: "linear-gradient(0deg, rgba(255,255,255,0.00) 4%, #FCEFEF 99%)",
  yellow3: "linear-gradient(0deg, rgba(255,255,255,0.00) 4%, #FEF7E0 99%)",
}
